import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import stockStore from "@/views/apps/stock/stockStore";

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCode} from "@core/utils/filter";

export default function salesorderitemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'name', label: '名称'},
    // { key: 'order_id', label: '销售订单ID', sortable: true },
    // { key: 'order_no', label: '销售订单编号', sortable: true },
    // { key: 'stock_id', label: 'ID', sortable: true },
    // { key: 'stock_no', label: '库存编号', sortable: true },
    // { key: 'warehouse_id', label: '库存所在仓库ID', sortable: true },
    // { key: 'warehouse_name', label: '库存所在仓库名称', sortable: true },
    // { key: 'available_cost', label: '可用成本', sortable: true },
    // { key: 'stocklock_id', label: '锁库ID', sortable: true },
    // { key: 'purchase_orderitem_id', label: '采购单产品明细ID', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    // { key: 'batch_no', label: '产品批次', sortable: true },
    // { key: 'production_date', label: '生产日期', sortable: true },
    // { key: 'expiry_date', label: '有效日期', sortable: true },
    { key: 'status', label: '库存状态'},
    { key: 'stock_qty', label: '库存数量', sortable: true },
    { key: 'production_date', label: '生产日期'},
    // { key: 'category_id', label: '分类ID', sortable: true },
    // { key: 'category_name', label: '分类名称', sortable: true },
    // { key: 'brand_id', label: '品牌ID', sortable: true },
    // { key: 'brand_name', label: '品牌名称', sortable: true },
    { key: 'is_gift', label: '是否赠品', sortable: true },
    { key: 'product_cost_tax', label: '含税售价'},
    { key: 'qty', label: '售卖数量'},
    // { key: 'product_cost_tax_subtotal', label: '含税单价小计 ', sortable: true },
    { key: 'tax_rate', label: '税率'},
    { key: 'tax_subtotal', label: '税额 '},
    { key: 'extra_cost', label: '附加费'},
    // { key: 'extra_cost_subtotal', label: '附加费小计 ', sortable: true },
    // { key: 'cost', label: '金额'},
    { key: 'subtotal', label: '价税合计 '},
    { key: 'cost_price', label: '成本价'},
    { key: 'gross_profit', label: '毛利 '},
    { key: 'gross_profit_rate', label: '毛利率 '},
    { key: 'expected_gross_profit_rate', label: '预计毛利率 '},
    { key: 'product_cost', label: '售价 '},
    { key: 'product_cost_subtotal', label: '单价小计 ', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    // { key: 'add_time', label: '添加时间', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
        { key: 'sales_order_item_actions', label: '操作' },
        // { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(100)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('item_id')
  const isSortDirDesc = ref(false)
  const list = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })


  const searchList = (ctx, callback) => {
    if (config.readonly!==0){
      for (let i = 0; i < tableColumns.length; i++) {
        if (tableColumns[i].key=="sales_order_item_actions"){
          tableColumns.splice(i,1)
        }
      }
    }
    store
      .dispatch('salesorderitem/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_id:config.order_id,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const lists = data.ext.list
        listTotals.value = data.ext.totals
        list.value = data.ext.list
        for (let i = 0; i < lists.length; i++) {
            lists[i].taxRate=getCode("tax_rate",lists[i].tax_rate)
            // console.log(getCode("stock_status",lists[i].tax_rate))
        }
          callback(lists)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // const getcostlist = function (ctx,callback) {
  //   store.dispatch('stockinbounditem/getcostlist', {stockid:57}).then(response => {
  //     console.log(1)
  //     console.log(res.data.data)
  //     const data = response.data.data
  //     const list = data.ext.list
  //     callback(res.data)
  //   })
  // }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    // addnewpro,
    // getcostlist,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    list:list,

    refetchData,

    // Extra Filters
  }
}
