<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <span>{{ name }}</span>
            <!-- Search -->
            <div class="d-flex align-items-center justify-content-end">
              <!--              <b-form-input-->
              <!--                  v-model="searchKey"-->
              <!--                  class="d-inline-block mr-1"-->
              <!--                  placeholder="搜索关键字..."-->
              <!--              />-->
              <b-button
                  variant="info"
                  @click="setCompanyId"
                  class="mr-1"
                  v-if="readonly===0"
              >
                <span class="text-nowrap">添加产品</span>
              </b-button>

              <b-button
                  variant="primary"
                  @click="showSalesOrderStock()"
                  v-if="readonly===0"
              >
                <span class="text-nowrap">自动选批次</span>
              </b-button>

            </div>
          </b-col>

          <b-modal
              id="modal-select-stock"
              ok-only
              ok-title="确认"
              @ok="onSelectStock"
              cancel-title="取消"
              centered
              size="xl"
              title="选择产品"
          >
            <stock-list
                ref="myStockSelect"
                :companyId="null"
                :basicStoreId="null"
                :warehouseid="warehouseid"
                :purchaseTeamUserId="purchaseTeamUserId"
                :purchaseTeamStoreId="purchaseTeamStoreId"
                v-if="showFlag">
            </stock-list>
          </b-modal>

          <b-modal
              id="salesorder-stock"
              ok-only
              ok-title="确认"
              @ok="onSelectSalesOrderStock"
              cancel-title="取消"
              centered
              size="xl"
              :title="'请选择'"
          >
            <stock-manage
                ref="salesorderStockSelect" :companyId="null" :basicStoreId="null" :warehouseid="warehouseid" :purchaseTeamUserId="purchaseTeamUserId" :purchaseTeamStoreId="purchaseTeamStoreId">
            </stock-manage>
          </b-modal>

        </b-row>

      </div>

      <b-table
          sticky-header="100%"
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="tableArray"
          :tbody-tr-class="rowClass"
      >

        <!-- Columns -->
        <!--        <template #cell(id)="data">-->
        <!--          <b-form-checkbox-->
        <!--              name="user-id"-->
        <!--              v-model="checked"-->
        <!--              :value="data.item"-->
        <!--          >-->
        <!--            #{{data.item.id}}-->
        <!--          </b-form-checkbox>-->
        <!--        </template>-->

        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <template #cell(name)="data">
          <div style="width: 150px"></div>
          {{data.index+1}}
          {{ data.item.name }}{{ data.item.is_gift===1?"(赠)":"" }}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+'商品69码：'+data.item.ext.productcode+
              '<br>'+'库存编号：'+data.item.stock_no+
              '<br>'+'产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '<br>'+'库存数量：'+data.item.ext.mystock+
              '<br>'+'待出库数量：'+data.item.ext.usedStock+
              '<br>'+'售价小计：'+data.item.product_cost_subtotal+
              '<br>'+'含税售价小计：'+data.item.product_cost_tax_subtotal+
              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(production_date)="data">
          {{toMonth(data.item.ext.productionDate)}}
        </template>

        <template #cell(is_gift)="data">
          <b-form-checkbox
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
              v-model="data.item.is_gift"
              @change="caltotal(data.item);toParent()"
          ></b-form-checkbox>
<!--          <feather-icon-->
<!--              icon="XIcon"-->
<!--              size="21"-->
<!--              v-if="data.item.is_gift==0"-->
<!--          />-->
<!--          <feather-icon-->
<!--              icon="CheckIcon"-->
<!--              size="21"-->
<!--              v-else-->
<!--          />-->
        </template>

        <template #cell(stock_qty)="data">
          {{data.item.ext.qty}}(待出库数量:{{data.item.ext.usedStock}})
        </template>

        <template #cell(available_cost)="data">
          <v-select
              id="available_cost"
              :options="a"
              :clearable="true"
              class="select-size-sm"
              @click.native="info(data)"
              v-if="flag"
          />
        </template>

        <!-- Column: Type -->
        <template #cell(salesorderitem_type)="data">
          {{ getCodeLabel('salesorderitem_type', data.item.salesorderitem_type) }}
        </template>

        <template #cell(expected_gross_profit_rate)="data">
          <span v-if="data.item.ext.expected_gross_profit_rate">
            {{ data.item.ext.expected_gross_profit_rate }}%
          </span>
        </template>

        <template #cell(gross_profit_rate)="data">
          {{ data.item.ext.gross_profit_rate }}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
          >
            {{ getCodeLabel("stock_status", data.item.status) }}
          </b-badge>
        </template>

        <!--        <template #cell(is_gift)="data">-->
        <!--          <b-form-checkbox-->
        <!--              name="check-button"-->
        <!--              switch-->
        <!--              inline-->
        <!--              v-model="data.item.is_gift"-->
        <!--              value="1"-->
        <!--              unchecked-value="0"-->
        <!--              @input="toParent()"-->
        <!--          ></b-form-checkbox>-->
        <!--        </template>-->


        <!--        售价-->
        <template #cell(product_cost)="data">
          <b-form-input type="number" readonly v-model="data.item.product_cost" @change="caltotal(data.item);toParent()"
                        style="width: 75px" size="sm">
          </b-form-input>
        </template>


        <!--        含税单价-->
        <template #cell(product_cost_tax)="data">
          <b-form-input type="number" v-model="data.item.product_cost_tax"
                        @change="changeProductCost(data.item);caltotal(data.item);toParent()" style="width: 75px" size="sm" :readonly="data.item.is_gift==1">
          </b-form-input>
        </template>

        <!--        售卖数量-->
        <template #cell(qty)="data">
          <div style="width:50px"></div>
          <b-form-input type="number" v-model="data.item.qty" @change="checkQty(data.item);toParent()"
                        style="width: 75px" size="sm">
          </b-form-input>
        </template>

        <!--        成本价-->
        <template #cell(cost_price)="data">
          {{ data.item.cost_price }}
        </template>

        <!--       税率-->
        <template #cell(tax_rate)="data">
          <b-form-input
              v-model="data.item.taxRate.label"
              readonly
              style="width: 75px"
              size="sm"
          >
          </b-form-input>
<!--          <v-select-->
<!--              id="tax_rate"-->
<!--              :options="getCodeOptions('tax_rate')"-->
<!--              :clearable="true"-->
<!--              v-model="data.item.taxRate"-->
<!--              @input="changeSelect(data.item,$event);caltotal(data.item);toParent()"-->
<!--              class="select-size-sm"-->
<!--              label="label"-->
<!--              @change="toParent()"-->
<!--              disabled-->
<!--          />-->
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(sales_order_item_actions)="data">
          <b-link @click="saveSalesOrderItem(data.item)">
            <feather-icon
                icon="SaveIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
          <b-link @click="delSalesOrderItem(data.item)">
            <feather-icon
                icon="XSquareIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
        </template>

        <!-- Column: Actions -->
        <!--        <template #cell(actions)="data">-->
        <!--          <b-dropdown-->
        <!--              variant="link"-->
        <!--              no-caret-->
        <!--          >-->

        <!--            <template #button-content>-->
        <!--              <feather-icon-->
        <!--                  icon="MoreVerticalIcon"-->
        <!--                  size="16"-->
        <!--                  class="align-middle text-body"-->
        <!--              />-->
        <!--            </template>-->

        <!--            <b-dropdown-item :to="{ name: 'apps-salesorderitem-view', query: { id: data.item.id } }">-->
        <!--              <feather-icon icon="FileTextIcon"/>-->
        <!--              <span class="align-middle ml-50">浏览</span>-->
        <!--            </b-dropdown-item>-->

        <!--            <b-dropdown-item :to="{ name: 'apps-salesorderitem-edit', query: { id: data.item.id } }">-->
        <!--              <feather-icon icon="EditIcon"/>-->
        <!--              <span class="align-middle ml-50">编辑</span>-->
        <!--            </b-dropdown-item>-->

        <!--          </b-dropdown>-->
        <!--        </template>-->

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>


          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BIcon, BootstrapVueIcons,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,toMonth} from '@core/utils/filter'
import salesorderitemUseList from './salesorderitemUseList'
import salesorderitemStore from './salesorderitemStore'
import stockinbounditemStore from '@/views/apps/stockinbounditem/stockinbounditemStore'
import stockStore from "@/views/apps/stock/stockStore";
import StockList from "@/views/apps/stock/StockList";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import axios from "@/libs/axios";
import {useToast} from "vue-toastification/composition";
import Ripple from 'vue-ripple-directive'
import StockManage from "@/views/apps/stock/salesorder-stock/StockManage";


export default {
  components: {
    StockManage,
    StockList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') { return }
      if (item.qty>item.ext.qty-item.ext.usedStock&&item.ext.status!=2) { return  'table-danger'}
    },
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('salesorderitem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    getChecked: function () {
      return this.checked
    },
    info: function (res) {
      store.dispatch('stockinbounditem/getcostlist', {stockid: res.item.id}).then(res => {
        this.a.length = 0
        const data = res.data.data
        for (let i = 0; i < data.length; i++) {
          this.a.push({"label": data[i].product_cost_tax, "item_id": data[i].item_id})
        }
        this.flag = true
      })
    },
    childByValue: function (childValue) {
      // childValue就是子组件传过来的值
      this.name = childValue
    },
    forceUpdate: function () {
      this.$forceUpdate()
    }
  },
  data() {
    return {
      items: [],
      checked: [],
      array: [],
      salesorderitem: ref({}),
      flag: false,
      a: [],
      flags: false,
      order_ids: {},
      name: '',
      salesorderitemarray: [],
      taxRate: {},
      tableArray: [],
      companyid: 0,
      warehouseid: 0,
      basicStoreId: 0,
      showFlag: false,
    }
  },
  props: ["order_id", "companyId","warehouseId", "storeName", "storeId", "readonly","purchaseTeamUserId","purchaseTeamStoreId"]
  ,
  setup(props) {
    const toast = useToast()
    // this.order_ids=props.order_id
    // this.flags=true

    // Register module
    if (!store.hasModule('salesorderitem')) store.registerModule('salesorderitem', salesorderitemStore)
    if (!store.hasModule('stockinbounditem')) store.registerModule('stockinbounditem', stockinbounditemStore)
    if (!store.hasModule('stock')) store.registerModule('stock', stockStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesorderitem')) store.unregisterModule('salesorderitem')
      if (store.hasModule('stockinbounditem')) store.unregisterModule('stockinbounditem')
      if (store.hasModule('stock')) store.unregisterModule('stock')
    })

    const onSelectStock = function (bvModalEvt) {
      let checked = this.$refs.myStockSelect.getChecked();
      this.items = this.items.concat(checked)
      let stockids = ""
      let stocklockids = ""
      let purchaseorderitemids = ""
      for (let i = 0; i < checked.length; i++) {
        stockids += checked[i].stock_id + ","
        stocklockids += checked[i].temp1SL.stocklock_id + ","
        purchaseorderitemids += checked[i].temp2SL.purchaseorderitem_id + ","
      }
      const result = {
        "order_id": props.order_id,
        "stockids": stockids.substring(0, stockids.length - 1),
        "stocklockids": stocklockids.substring(0, stocklockids.length - 1),
        "purchaseorderitemids": purchaseorderitemids.substring(0, purchaseorderitemids.length - 1),
      }
      // console.log("result",result)
      store
          .dispatch('salesorderitem/addnewpros', {
            orderid: result.order_id,
            stockids: result.stockids,
            stocklockids: result.stocklockids,
            purchaseorderitemids: result.purchaseorderitemids,
          })
          .then(res => {
            if (res.data.code == 0) {
              toast.success("添加成功")
              refetchData()
            } else {
              toast.error(res.data.data)
            }

          })
          .catch((e) => {
          })
      // refetchData()
      this.flag = true
      this.items.length = 0
    }

    const saveSalesOrderItem = function (result) {
      if (result.product_cost_tax < 0) {
        toast.error("含税售价必须大于等于0!")
        return false
      }
      if (result.qty <= 0) {
        toast.error("售卖数量必须大于0!")
        return false
      }
      if (result.ext.qty < result.qty) {
        toast.error(result.name + "超过最大数量!")
        result.qty = 0
        return false
      }
      store
          .dispatch('salesorderitem/updateOrderItem', {
            itemid: result.item_id,
            qty: result.qty,
            productcost: result.product_cost,
            productcosttax: result.product_cost_tax,
            taxrate: result.tax_rate,
            costprice: result.cost_price,
            isgift: result.is_gift,
          })
          .then(res => {
            if (res.data.code == 0) {
              toast.success('数据已保存!')
              // refetchData()
            } else {
              toast.error(res.data.data)
            }

          })
          .catch((e) => {
            toast.error("保存失败")
          })
    }

    const caltotal = function (result) {
      if (result.is_gift==1){
        result.product_cost_tax = 0
        result.product_cost = 0
      }
      if (result.product_cost_tax !== "" && result.product_cost !== "") {
        store
            .dispatch('salesorderitem/caltotal', {
              itemid: result.item_id,
              qty: result.qty,
              productcost: result.product_cost,
              productcosttax: result.product_cost_tax,
              taxrate: result.tax_rate,
              costprice: result.cost_price,
            })
            .then(response => {
              let val = response.data.data
              result.product_cost = val.product_cost
              result.product_cost_subtotal = val.product_cost_subtotal
              result.product_cost_tax = val.product_cost_tax
              result.product_cost_tax_subtotal = val.product_cost_tax_subtotal
              result.tax_rate = val.tax_rate
              result.tax_subtotal = val.tax_subtotal
              result.extra_cost = val.extra_cost
              result.extra_cost_subtotal = val.extra_cost_subtotal
              result.cost = val.cost
              result.subtotal = val.subtotal
              result.gross_profit = val.gross_profit
              result.ext.gross_profit_rate = val.ext.gross_profit_rate
            })
      } else {
        toast.error("请输入正确值!")
      }


    }

    const toParent = function () {
      this.$emit("table", this.tableArray)
    }

    const changeProductCost = function (params) {
      params.product_cost = 0
    }

    const delSalesOrderItem = function (result) {
      var res = confirm("确定删除吗？")
      if (res) {
        store
            .dispatch('salesorderitem/delorderitem', {
              id: result.item_id,
            })
            .then(res => {
              if (res.data.code==0){
                toast.success("删除成功!")
                refetchData()
              }else{
                toast.error(res.data.data)
              }
            })
            .catch((e) => {

            })
      }
    }

    const changeIsGit = function (res) {
      if (res === 0) {
        return "否"
      } else {
        return "是"
      }
    }

    const changeSelect = function (res, event) {
      if (event != null) {
        res.tax_rate = parseFloat(event.value)
        // res.product_cost_tax = (res.product_cost)*(1+res.tax_rate)
        res.taxRate = event.label
        // this.taxRate = getCode('tax_rate', res.item.tax_rate).label
        caltotal(res)
      } else {
        res.tax_rate = parseFloat('0')
        // res.product_cost_tax = (res.product_cost)*(1+res.tax_rate)
        res.taxRate = '0%'
        caltotal(res)
      }

    }

    const checkQty = function (params) {
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(params.qty)) {
        toast.error('售卖数量必须是正整数!')
        params.qty = 0
        return false
      }
      let num = 0
      for (let i = 0; i < this.tableArray.length; i++) {
        if (this.tableArray[i].batch_no === params.batch_no && this.tableArray[i].stock_no === params.stock_no) {
          num += parseInt(this.tableArray[i].qty==undefined?0:this.tableArray[i].qty)
        }
      }
      axios
          .post('/api/stock/getStock', {
            stockid: params.stock_id,
            stocklockid: params.stocklock_id,
          })
          .then(response => {
            if (num > response.data.data.ext.totalstocklock-params.ext.usedStock) {
              toast.error("相同批次的" + params.name + "超过最大数量("+(response.data.data.ext.totalstocklock-params.ext.usedStock)+")!")
              params.qty = 0
              params.tax_subtotal = 0
              params.cost = 0
              params.subtotal = 0
              params.gross_profit = 0
              params.ext.gross_profit_rate = 0
            } else {
              caltotal(params)
            }
          })
    }

    const isGift = function (params) {
      if (params === 1) {
        return "(赠)"
      } else {
        return "(非赠)"
      }
    }

    const setCompanyId = function () {
      if (props.companyId == undefined) {
        toast.error("请选择主体！")
        return false
      }

      if (props.storeId == undefined) {
        toast.error("请选择店铺名称")
        return false
      }

      if (props.warehouseId == undefined) {
        toast.error("请选择仓库")
        return false
      }

      this.companyid = props.companyId
      this.basicStoreId = props.storeId
      this.warehouseid = props.warehouseId
      this.showFlag = false
      this.$nextTick(() => {
        this.showFlag = true
      })
      this.$bvModal.show('modal-select-stock')
    }

    const showSalesOrderStock = function(){
      if (props.companyId == undefined) {
        toast.error("请选择主体！")
        return false
      }

      if (props.storeId == undefined) {
        toast.error("请选择店铺名称")
        return false
      }

      if (props.warehouseId == undefined) {
        toast.error("请选择仓库")
        return false
      }

      this.$bvModal.show('salesorder-stock')
      this.companyid = props.companyId
      this.basicStoreId = props.storeId
      this.warehouseid = props.warehouseId

    }

    const onSelectSalesOrderStock = function (){
      let checked = this.$refs.salesorderStockSelect.getChecked();
      let stockids = ""
      let stocklockids = ""
      let purchaseorderitemids = ""
      let qty = ""
      for (let i = 0; i < checked.length; i++) {
        stockids += checked[i].stock_id + ","
        stocklockids += 0 + ","
        purchaseorderitemids += checked[i].purchaseorder_itemid + ","
        qty += checked[i].ext.useQty+","
      }
      const result = {
        "order_id": props.order_id,
        "stockids": stockids.substring(0, stockids.length - 1),
        "stocklockids": stocklockids.substring(0, stocklockids.length - 1),
        "purchaseorderitemids": purchaseorderitemids.substring(0, purchaseorderitemids.length - 1),
      }

      store
          .dispatch('salesorderitem/addnewpros', {
            orderid: result.order_id,
            stockids: result.stockids,
            stocklockids: result.stocklockids,
            purchaseorderitemids: result.purchaseorderitemids,
            qty:qty
          })
          .then(res => {
            if (res.data.code == 0) {
              toast.success("添加成功")
              refetchData()
            } else {
              toast.error(res.data.data)
            }

          })
          .catch((e) => {
          })
      // refetchData()
      this.flag = true
      this.items.length = 0

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      list,

      // UI
    } = salesorderitemUseList({
      order_id: props.order_id,
      readonly: props.readonly
    })

    return {
      list,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toMonth,
      // UI
      onSelectStock,
      toTime, toDate,

      changeIsGit,
      delSalesOrderItem,
      saveSalesOrderItem,
      caltotal,
      changeSelect,
      checkQty,
      changeProductCost,
      toParent,
      isGift,
      setCompanyId,
      showSalesOrderStock,
      onSelectSalesOrderStock,

    }
  },
  created() {
    // this.blurProductCostTax(this.list)
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

button {
  float: left;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
