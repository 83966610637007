<template>
  <b-card
      class="salesorder-edit-wrapper"
  >
    <validation-observer ref="simpleRules">
      <!-- form -->
      <b-form id="salesorderForm" class="edit-form mt-2">
        <b-row>
<!--          仓库人员隐藏-->
          <div>
            <b-row>
              <b-col md="12" >
                <div class="inner-card-title">基本信息</div>
              </b-col>

              <b-col :md="oneSwitch == '1'?6:4">
                <b-card header="基本信息">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="编号"
                          label-for="order_no"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="order_no"
                            size="sm"
                            v-model="salesorder.order_no"
                            readonly
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="业务类型"
                          label-for="type_id"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <v-select
                            id="type_id"
                            :options="getCodeOptions('business_type').filter(function(datas) {
                              return datas.value!=5;
                            })"
                            :clearable="true"
                            v-model="typeId"
                            @input="changeSelect('type_id',$event)"
                            class="select-size-sm"
                            placeholder="请选择业务类型"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="贸易方式"
                          label-for="trade_type"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <v-select
                            id="trade_type"
                            :options="getCodeOptions('trade_type')"
                            :clearable="true"
                            v-model="tradeType"
                            @input="changeSelect('trade_type',$event)"
                            class="select-size-sm"
                            placeholder="请选择贸易方式"
                        />
                      </b-form-group>
                    </b-col>
<!--                    <b-col md="12">-->
<!--                      <b-form-group-->
<!--                          label-cols="3"-->
<!--                          label-cols-lg="3"-->
<!--                          label="主体名称"-->
<!--                          label-for="company_name"-->
<!--                          label-size="sm"-->
<!--                          class="mb-1 required"-->
<!--                      >-->
<!--                        <v-select-->
<!--                            id="company_name"-->
<!--                            :options="companyNameArray"-->
<!--                            :clearable="true"-->
<!--                            class="select-size-sm"-->
<!--                            v-model="companyName"-->
<!--                            @input="setCompanyName"-->
<!--                            placeholder="请选择主体名称"-->
<!--                        />-->
<!--                      </b-form-group>-->
<!--                    </b-col>-->
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="销售员名称"
                          label-for="label"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <v-select
                            :options="salesUserArray.length===0?[]:salesUserArray"
                            :clearable="true"
                            class="select-size-sm"
                            v-model="salesUser"
                            @input="changeSelect('sales_id',$event)"
                            placeholder="请选择销售员"
                            :disabled="readonly!==0"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="销售员电话"
                          label-for="sales_mobile"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="sales_mobile"
                            size="sm"
                            v-model="salesorder.sales_mobile"
                            readonly
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col :md="oneSwitch == '1'?6:4">
                <b-card header="渠道方/店铺信息">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="渠道方"
                          label-for="label"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <v-select
                            id="channel_name"
                            :options="channelNameArray"
                            :clearable="true"
                            class="select-size-sm"
                            v-model="channelName"
                            @input="setChannelName($event)"
                            placeholder="请选择渠道方"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="渠道联系人"
                          label-for="channel_contact_name"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="channel_contact_name"
                            size="sm"
                            v-model="salesorder.channel_contact_name"
                            readonly
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="渠道联系电话"
                          label-for="channel_contact_mobile"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="channel_contact_mobile"
                            size="sm"
                            v-model="salesorder.channel_contact_mobile"
                            readonly
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="店铺名称"
                          label-for="label"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <v-select
                            id="store_name"
                            :options="storeArray"
                            :clearable="true"
                            class="select-size-sm"
                            v-model="storeName"
                            @input="setStore($event)"
                            placeholder="请选择店铺"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="仓库名称"
                          label-for="label"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <v-select
                            id="sales_warehouse"
                            :options="salesWarehouseArray.length===0?[]:salesWarehouseArray"
                            :clearable="true"
                            class="select-size-sm"
                            v-model="salesWarehouse"
                            @input="changeSelect('sales_warehouse',$event)"
                            placeholder="请选择仓库"
                            :disabled="readonly!==0"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="售卖时间"
                          label-for="label"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <flat-pickr
                            v-model="salesorder.sales_time"
                            class="form-control"
                            placeholder="请选择售卖时间"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>

              <b-col md="4" v-if="oneSwitch != '1'">
                <b-card header="合同信息">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="渠道合同编号"
                          label-for="channel_order_no"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="channel_order_no"
                            size="sm"
                            v-model="salesorder.channel_order_no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="合同编号"
                          label-for="contract_no"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="contract_no"
                            size="sm"
                            v-model="salesorder.contract_no"
                            @input="contractNo"
                            v-b-modal.modal-select-contract
                            placeholder="点击搜索合同"
                        />

                      </b-form-group>

                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="合同类型"
                          label-for="label"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <v-select
                            id="contract_type"
                            :options="getCodeOptions('agreement_specific_type_XS')"
                            :clearable="true"
                            v-model="contractType"
                            @input="changeSelect('contract_type',$event)"
                            class="select-size-sm"
                            placeholder="请选择合同类型"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>

              <b-col md="12">
                <b-card header="其他信息">
                  <b-row>
                    <b-col md="4">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="发票类型"
                          label-for="invoice_type"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <v-select
                            id="invoice_type"
                            :options="getCodeOptions('invoice_type')"
                            :clearable="true"
                            v-model="invoiceType"
                            @input="changeSelect('invoice_type',$event)"
                            class="select-size-sm"
                            placeholder="请选择发票类型"
                        />
                      </b-form-group>
                    </b-col>
                    <!--                <b-col md="4">
                                      <b-form-group
                                          label-cols="3"
                                          label-cols-lg="3"
                                          label="付款方式"
                                          label-for="payment_method"
                                          label-size="sm"
                                          class="mb-1 required"
                                      >
                                        <v-select
                                            id="payment_method"
                                            :options="getCodeOptions('agreement_pay_method')"
                                            :clearable="true"
                                            v-model="paymentMethod"
                                            @input="changeSelect('payment_method',$event)"
                                            class="select-size-sm"
                                            placeholder="请选择付款方式"
                                        />
                                      </b-form-group>
                                    </b-col>-->
                    <b-col md="4">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="是否预付款"
                          label-for="need_advance"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="need_advance"
                            v-model="salesorder.need_advance"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <!--                <b-col md="4">
                                      <b-form-group
                                          label-cols="3"
                                          label-cols-lg="3"
                                          label="结算方式"
                                          label-for="settlement_method"
                                          label-size="sm"
                                          class="mb-1 required"
                                      >
                                        <v-select
                                            id="settlement_method"
                                            :options="getCodeOptions('settlement_method')"
                                            :clearable="true"
                                            v-model="settlementMethod"
                                            @input="changeSelect('settlement_method',$event)"
                                            class="select-size-sm"
                                            placeholder="请选择结算方式"
                                        />
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                      <b-form-group
                                          label-cols="3"
                                          label-cols-lg="3"
                                          label="结算次数"
                                          label-for="settlement_times"
                                          label-size="sm"
                                          class="mb-1"
                                      >
                                        <b-form-input
                                            id="settlement_times"
                                            size="sm"
                                            v-model="salesorder.settlement_times"
                                        />
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                      <b-form-group
                                          label-cols="3"
                                          label-cols-lg="3"
                                          label="物流方式"
                                          label-for="delivery_type"
                                          label-size="sm"
                                          class="mb-1 required"
                                      >
                                        <v-select
                                            id="delivery_type"
                                            :options="getCodeOptions('delivery_method_sale')"
                                            :clearable="true"
                                            v-model="deliveryType"
                                            @input="changeSelect('delivery_type',$event)"
                                            class="select-size-sm"
                                            placeholder="请选择物流方式"
                                        />
                                      </b-form-group>
                                    </b-col>-->
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="1"
                          label="备注"
                          label-for="remark"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-textarea
                            id="remark"
                            size="sm"
                            v-model="salesorder.remark"
                            rows="3"
                            max-rows="6"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="1"
                          label-cols-lg="1"
                          label="附件上传"
                          label-for="attachments"
                          label-size="sm"
                          :class="'mb-1'"
                      >
                        <attachment-upload v-if="salesorder.loaded" :theme="'files'"
                                           :attachment_id="'attachments'"
                                           :id="salesorder.attachments"
                                           :object_type="'sales_order'"
                                           :object_id="salesorder.order_id"
                                           @change="onUploaded"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </div>

<!--          <b-col v-if="user.role_id==9||user.role_id==10" md="4">-->
<!--            <b-row>-->
<!--              <b-col md="12">-->
<!--                <div class="inner-card-title">基本信息</div>-->
<!--              </b-col>-->
<!--              <b-col md="12">-->
<!--                <b-card header="基本信息">-->
<!--                  <b-row>-->
<!--                    <b-col md="12">-->
<!--                      <b-form-group-->
<!--                          label-cols="3"-->
<!--                          label-cols-lg="3"-->
<!--                          label="主体名称"-->
<!--                          label-for="company_name"-->
<!--                          label-size="sm"-->
<!--                          class="mb-1 required"-->
<!--                      >-->
<!--                        <v-select-->
<!--                            :options="companyNameArray"-->
<!--                            :clearable="true"-->
<!--                            class="select-size-sm"-->
<!--                            v-model="companyName"-->
<!--                            @input="setCompanyName"-->
<!--                            placeholder="请选择主体名称"-->
<!--                        />-->
<!--                      </b-form-group>-->
<!--                    </b-col>-->
<!--                  </b-row>-->
<!--                </b-card>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--          </b-col>-->

<!--          <b-col md="12">
            <div class="inner-card-title">附加费</div>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="附加费"
                label-for="extra_cost_subtotal"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="extra_cost_subtotal"
                  size="sm"
                  v-model="salesorder.extra_cost_subtotal"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-button variant="outline-primary" size="sm" @click="addExtraCostSubtotal">确认添加</b-button>
          </b-col>
          -->

          <b-col md="12">
            <div class="inner-card-title">销售单明细</div>
          </b-col>

          <b-col md="12">
            <sales-order-item-list
                :order_id="salesorder.order_id"
                :companyId="salesorder.company_id"
                :storeName="salesorder.store_name"
                :warehouseId="salesorder.sales_warehouse"
                :storeId="salesorder.store_id"
                :purchaseTeamUserId="salesorder.sales_id"
                :purchaseTeamStoreId="salesorder.store_id"
                :readonly="readonly"
                v-if="itemListShowFlag"
                ref="itemList"
                v-on:table="fromChildren" >
            </sales-order-item-list>
          </b-col>

          <!--        <b-tab-->
          <!--            title="明细"-->
          <!--            @click="switchTab"-->
          <!--        >-->
          <!--          <purchase-order-item-list ref="itemList" :order_id="purchaseorder.order_id">-->

          <!--          </purchase-order-item-list>-->
          <!--        </b-tab>-->


          <!--        <b-col md="3">-->
          <!--          <b-form-group-->
          <!--              label-cols="3"-->
          <!--              label-cols-lg="3"-->
          <!--              label="创建时间"-->
          <!--              label-for="add_time"-->
          <!--              label-size="sm"-->
          <!--              class="mb-1"-->
          <!--          >-->
          <!--            <b-form-input-->
          <!--                id="add_time"-->
          <!--                size="sm"-->
          <!--                v-model="salesorder.add_time"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <!--        </b-col>-->
          <!--        <b-col md="3">-->
          <!--          <b-form-group-->
          <!--              label-cols="3"-->
          <!--              label-cols-lg="3"-->
          <!--              label="创建人"-->
          <!--              label-for="creator"-->
          <!--              label-size="sm"-->
          <!--              class="mb-1"-->
          <!--          >-->
          <!--            <b-form-input-->
          <!--                id="creator"-->
          <!--                size="sm"-->
          <!--                v-model="salesorder.creator"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <!--        </b-col>-->
          <!--        <b-col md="3">-->
          <!--          <b-form-group-->
          <!--              label-cols="3"-->
          <!--              label-cols-lg="3"-->
          <!--              label="修改时间"-->
          <!--              label-for="modify_time"-->
          <!--              label-size="sm"-->
          <!--              class="mb-1"-->
          <!--          >-->
          <!--            <b-form-input-->
          <!--                id="modify_time"-->
          <!--                size="sm"-->
          <!--                v-model="salesorder.modify_time"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <!--        </b-col>-->
          <!--        <b-col md="3">-->
          <!--          <b-form-group-->
          <!--              label-cols="3"-->
          <!--              label-cols-lg="3"-->
          <!--              label="修改人"-->
          <!--              label-for="updator"-->
          <!--              label-size="sm"-->
          <!--              class="mb-1"-->
          <!--          >-->
          <!--            <b-form-input-->
          <!--                id="updator"-->
          <!--                size="sm"-->
          <!--                v-model="salesorder.updator"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <!--        </b-col>-->
          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="success"
                class="mr-1"
                @click="save"
                v-if="readonly===0"
            >
              保存
            </b-button>
            <b-button
                variant="outline-secondary"
                @click="cancel"
                class="mr-1"
            >
              返回
            </b-button>
            <b-button
                variant="primary"
                @click="saveAndSubmit"
                class="mr-1"
                v-if="readonly===0"
            >
              提交
            </b-button>
          </b-col>
        </b-row>
      </b-form>

<!--      <b-modal-->
<!--          id="modal-select-user"-->
<!--          ok-only-->
<!--          ok-title="确认"-->
<!--          @ok="onSelectUser"-->
<!--          cancel-title="取消"-->
<!--          centered-->
<!--          size="lg"-->
<!--          title="选择姓名"-->
<!--      >-->
<!--        <user-select-->
<!--            ref="myUserSelect" v-on:table="fromChildrenDB($event,'modal-select-user')"-->
<!--        >-->
<!--        </user-select>-->
<!--      </b-modal>-->

      <b-modal
          id="modal-select-contract"
          ok-only
          ok-title="确认"
          @ok="onSelectContract"
          cancel-title="取消"
          centered
          size="lg"
          title="选择合同"
      >
        <contract-select
            ref="contractSelect" :type="2" :status="2" v-on:table="fromChildrenDB($event,'modal-select-contract')"
        >
        </contract-select>
      </b-modal>
      <!--/ form -->
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import salesorderStore from './salesorderStore'
import salesorderitemStore from "@/views/apps/salesorderitem/salesorderitemStore";
import storeStore from "@/views/apps/store/storeStore";
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second, isEmpty} from '@core/utils/filter'
import axios from "@/libs/axios";
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import ContractSelect from "@/views/apps/contract/ContractSelect";
import SalesOrderItemList from "@/views/apps/salesorderitem/SalesOrderItemList";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {getUserData} from "@/auth/utils";
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import storesalesStore from "@/views/apps/storesales/storesalesStore";

export default {
  components: {
    SalesOrderItemList,
    ContractSelect,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    UserSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      salesorder: ref({}),
      typeId: {},
      oneSwitch: null,
      channelNameArray: [],
      channelName: {},
      a: [],
      tradeType: {},
      required,
      email,
      companyNameArray: [],
      companyName: {},
      items: [],
      salesName: {},
      contractNo: {},
      contractType: {},
      invoiceType: {},
      paymentMethod: {},
      deliveryType: {},
      status: {},
      state: {},
      settlementMethod: {},
      itemListShowFlag: false,
      salesOrderItem: [],
      storeArray: [],
      storeName: "",
      readonly:0,
      user:{},
      salesWarehouseArray: [],
      salesWarehouse: "",
      salesUserArray: [],
      salesUser: "",

    }
  },
  methods: {
    setChannelName(params) {
      if (!isEmpty(params)){
        this.salesorder.channel_name = this.channelName.label
        this.salesorder.channel_id = this.channelName.channel_id
        this.salesorder.channel_contact_id = this.channelName.channel_contact_id
        this.salesorder.channel_contact_name = this.a[0].channel_contact_name
        this.salesorder.channel_contact_mobile = this.a[0].channel_contact_mobile
      }else {
        this.salesorder.channel_name = null
        this.salesorder.channel_id = null
        this.salesorder.channel_contact_id = null
        this.salesorder.channel_contact_name = null
        this.salesorder.channel_contact_mobile = null
      }
      this.salesorder.store_id = null
      this.salesorder.store_name = null
      this.salesorder.store_company_id = null
      this.storeName = null

    },
    setCompanyName() {
      if (this.companyName != null) {
        this.salesorder.company_name = this.companyName.label
        this.salesorder.company_id = this.companyName.company_id
        this.itemListShowFlag = false
        this.$nextTick(() => {
          this.itemListShowFlag = true
        })
      } else {
        this.salesorder.company_name = null
        this.salesorder.company_id = null
      }
    },
    setTime() {

    }

  },
  props: {
    order_id: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('salesorder')) store.registerModule('salesorder', salesorderStore)
    if (!store.hasModule('salesorderitem')) store.registerModule('salesorderitem', salesorderitemStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('storesales')) store.registerModule('storesales', storesalesStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesorder')) store.unregisterModule('salesorder')
      if (store.hasModule('salesorderitem')) store.unregisterModule('salesorderitem')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('storesales')) store.unregisterModule('storesales')
    })

    const edit = function () {
      store.dispatch('salesorder/edit', {id: this.id}).then(res => {
        if (res.data.code==1){
          toast.error(res.data.data)
          this.$router.push({ name: 'apps-salesorder-list' })
        }
        this.salesorder = res.data.data
        // alert(this.salesorder.order_id)
        this.itemListShowFlag = true
        this.typeId = getCode('business_type', this.salesorder.type_id)
        getCompanyByCompanyTypeIsOne(this.channelNameArray, this.channelNameArray.channel_contact_id, this.a)
        this.channelName = this.salesorder.channel_name
        this.tradeType = getCode('trade_type', this.salesorder.trade_type)
        getCompanyByCompanyTypeIsTwo(this.companyNameArray)
        this.companyName = this.salesorder.company_name
        this.contractType = getCode("agreement_specific_type_XS", this.salesorder.contract_type)
        this.invoiceType = getCode("invoice_type", this.salesorder.invoice_type)
        this.paymentMethod = getCode("agreement_pay_method", this.salesorder.payment_method)
        this.deliveryType = getCode("delivery_method_sale", this.salesorder.delivery_type)
        this.settlementMethod = getCode("settlement_method", this.salesorder.settlement_method)
        this.status = getCode("contract_status", this.salesorder.status)
        this.state = getCode("state", this.salesorder.state)
        this.storeName = this.salesorder.store_name
        this.salesorder.sales_time = toTime(this.salesorder.sales_time)
        this.salesWarehouse = getCodeLabel("warehouse", this.salesorder.sales_warehouse)
        this.salesUser = getCodeLabel("user", this.salesorder.sales_id)
        createStore(this.storeArray,this)
      })
    }

    const view = function () {
      store.dispatch('salesorder/view', {id: this.id}).then(res => {
        this.salesorder = res.data.data
      })
    }

    const cancel = function () {
      this.$router.push({name: 'apps-salesorder-list'})
    }

    const save = function () {
      if (this.salesorder.type_id === undefined || this.salesorder.type_id === "") {
        toast.error("请选择 业务类型!")
        return false
      }
      if (this.salesorder.channel_name === undefined || this.salesorder.channel_name === "") {
        toast.error("请选择 渠道方!")
        return false
      }
      if (this.salesorder.trade_type === undefined || this.salesorder.trade_type === "") {
        toast.error("请选择  贸易方式!")
        return false
      }
      if (this.salesorder.company_name === undefined || this.salesorder.company_name === "") {
        toast.error("请选择  主体名称!")
        return false
      }
      if (isEmpty(this.salesorder.sales_name)) {
        toast.error("请选择  销售员名称!")
        return false
      }
      if (this.salesorder.contract_type === undefined || this.salesorder.contract_type === "") {
        toast.error("请选择  合同类型!")
        return false
      }
      if (this.salesorder.invoice_type === undefined || this.salesorder.invoice_type === "") {
        toast.error("请选择  发票类型!")
        return false
      }
      /*if (this.salesorder.settlement_method === undefined || this.salesorder.settlement_method === "") {
        toast.error("请选择  结算方式!")
        return false
      }
      if (this.salesorder.delivery_type === undefined || this.salesorder.delivery_type === "") {
        toast.error("请选择  物流方式!")
        return false
      }
      if (this.salesorder.payment_method === undefined || this.salesorder.payment_method === "") {
        toast.error("请选择  付款方式!")
        return false
      }*/
      if (this.salesorder.store_id === undefined || this.salesorder.store_id === "") {
        toast.error("请选择  店铺!")
        return false
      }
      if (this.salesorder.sales_time === undefined || this.salesorder.sales_time === "") {
        toast.error("请填写  售卖时间!")
        return false
      }

      if (this.salesorder.sales_warehouse === undefined || this.salesorder.sales_warehouse === ""||this.salesorder.sales_warehouse == null) {
        toast.error("请选择  仓库!")
        return false
      }

      let itemData = this.$refs.itemList.tableArray

      for (let i = 0; i < itemData.length; i++) {
        if (itemData[i].product_cost_tax < 0) {
          toast.error("含税售价必须大于等于0!")
          return false
        }
        if (itemData[i].qty<=0){
          toast.error("售卖数量必须大于0!")
          return false
        }
      }

      this.salesorder.sales_time = second(this.salesorder.sales_time)

      for (let i = 0; i < this.salesOrderItem.length; i++) {
        saveSalesOrderItem(this.salesOrderItem[i])
      }

      store.dispatch('salesorder/save', this.salesorder).then(res => {
        if (res.data.code==0){
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-salesorder-list'})
        }else {
          toast.error(res.data.data)
        }

      })

      this.salesorder.sales_time = toTime(this.salesorder.sales_time)
    }

    const fromChildren = function (val) {
      this.salesOrderItem = val
      // console.log("这是从子页面传来的", this.salesOrderItem)
    }

    const saveSalesOrderItem = function (result) {
      store
          .dispatch('salesorderitem/updateOrderItem', {
            itemid: result.item_id,
            qty: result.qty,
            productcost: result.product_cost,
            productcosttax: result.product_cost_tax,
            taxrate: result.tax_rate,
            costprice: result.cost_price,
            isgift: result.is_gift,
          })
          .then(res => {
            // refetchData()
            if (res.data.code==0){
              toast.success(result.name + '数据已保存!')
            }else {
              toast.error(res.data.data)
            }

          })
          .catch((e) => {
            toast.error(result.name + "数据保存失败")
          })
    }

    const changeSelect = function (key, event) {
      if (event != null) {
        this.salesorder[key] = event.value
      } else {
        this.salesorder[key] = null
      }
      if (key=='sales_warehouse'){
        store.dispatch('salesorder/delSOItem', {orderId: this.salesorder.order_id}).then(res => {
          this.$refs.itemList.refetchData();
        })
      }
      if (key=='sales_id'){
        if (event != null) {
          this.salesorder.sales_name = event.label
          this.salesorder.sales_mobile = event.mobile
        } else {
          this.salesorder.sales_name = null
          this.salesorder.sales_mobile = null
        }

      }
    }

    const getCompanyByCompanyTypeIsOne = function (d, e, f) {
      axios.get('/api/company/list', {
        params: {
          company_type: 1
        }
      })
          .then(function (res) {
            const a = res.data.data.list
            for (let i = 0; i < a.length; i++) {
              d.push({
                "label": a[i].company_name, "channel_id": a[i].company_id, "channel_contact_id": a[i].user_id
              })
            }
            getUser(1, d[0].channel_contact_id, f)
          })

          .catch(function (res) {
            alert("失败")
          })
    }

    const getCompanyByCompanyTypeIsTwo = function (d) {
      axios.get('/api/company/list', {
        params: {
          company_type: 2
        }
      })
          .then(function (res) {
            const a = res.data.data.list
            for (let i = 0; i < a.length; i++) {
              d.push({"label": a[i].company_name, "company_id": a[i].company_id})
            }
          })
          .catch(function (res) {
            alert("失败")
          })
    }

    const getUser = function (res, userId, r) {
      axios.get('/api/user/edit', {
        params: {
          id: userId
        }
      })
          .then(function (response) {
            r.push({
              "channel_contact_name": response.data.data.full_name,
              "channel_contact_mobile": response.data.data.mobile
            })
          })
          .catch(function (error) {
            console.log(error);
          })

    }

    const onSelectUser = function (bvModalEvt) {
      let checked = this.$refs.myUserSelect.getSelected();
      this.items = this.items.concat(checked)
      this.salesName = this.items[0].full_name
      this.salesorder.sales_name = this.salesName
      this.salesorder.sales_id = this.items[0].user_id
      this.salesorder.sales_mobile = this.items[0].mobile
      this.items.length = 0
    }

    const onSelectContract = function (bvModalEvt) {
      let checked = this.$refs.contractSelect.getSelected()[0];
      this.items = this.items.concat(checked)
      this.contractNo = this.items[0].contract_no
      this.salesorder.contract_no = this.contractNo
      this.salesorder.contract_id = this.items[0].contract_id
      this.items.length = 0
    }

    const addExtraCostSubtotal = function () {
      store.dispatch('salesorderitem/calextracost', {
        orderid: this.salesorder.order_id,
        extracost: this.salesorder.extra_cost_subtotal,
      })
          .then(res => {
            toast.success('success!')
            this.$refs.itemList.refetchData();
          })

    }

    const setStore = function (event) {
      if (event != null) {
        this.salesorder.store_id = event.store_id
        this.salesorder.store_name = event.label
        this.salesorder.store_company_id = event.store_company_id
        //仓库清空
        const salesWarehouseArray = this.salesWarehouseArray
        salesWarehouseArray.length = 0
        this.salesorder.sales_warehouse = null
        this.salesWarehouse = ""
        store.dispatch('warehouse/findWareHouseIdsByStoreIds', {storeId: this.salesorder.store_id})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                salesWarehouseArray.push({"label": data[i].warehouse_name, "value": data[i].warehouse_id})
              }
            })
        //销售员清空
        const salesUserArray = this.salesUserArray
        salesUserArray.length = 0
        this.salesorder.sales_id = null
        this.salesorder.sales_name = null
        this.salesorder.sales_mobile = null
        this.salesorder.salesUserArray = null
        this.salesUser = ""
        store.dispatch('storesales/getUserByStoreId', {storeId: this.salesorder.store_id,type:1})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                salesUserArray.push({"label": data[i].full_name, "value": data[i].user_id,"mobile":data[i].mobile})
              }
            })
      }else {
        this.salesorder.store_id = null
        this.salesorder.store_name = null
        this.salesorder.store_company_id = null
      }
    }

    const createStore = function (params,_this) {
      store.dispatch('store/search')
          .then(function (response) {
            let data = response.data.data.list
            for (let i = 0; i < data.length; i++) {
              params.push({"label":data[i].store_name,"store_id":data[i].store_id,"store_company_id":data[i].company_id})
            }
          })
      if (!isEmpty(_this.salesorder.store_id)){
        //仓库赋值
        store.dispatch('warehouse/findWareHouseIdsByStoreIds', {storeId: isEmpty(_this.salesorder.store_id)?0:_this.salesorder.store_id})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                _this.salesWarehouseArray.push({"label": data[i].warehouse_name, "value": data[i].warehouse_id})
              }
            })
        //销售员赋值
        store.dispatch('storesales/getUserByStoreId', {storeId: isEmpty(_this.salesorder.store_id)?0:_this.salesorder.store_id,type:1})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                _this.salesUserArray.push({"label": data[i].full_name, "value": data[i].user_id,"mobile":data[i].mobile})
              }
            })
      }
    }

    const fromChildrenDB = function (checked,modal) {
      if (modal=='modal-select-user'){
        this.salesName = checked.full_name
        this.salesorder.sales_name = this.salesName
        this.salesorder.sales_id = checked.user_id
        this.salesorder.sales_mobile = checked.mobile
      }
      if (modal=='modal-select-contract'){
        this.contractNo = checked.contract_no
        this.salesorder.contract_no = this.contractNo
        this.salesorder.contract_id = checked.contract_id
      }
      this.$bvModal.hide(modal)
    }

    const onUploaded = function (id, attachment, result) {
      this.salesorder[id] = result
    }

    const saveAndSubmit = function () {
      if (confirm("是否提交?")){
        if (this.salesorder.type_id === undefined || this.salesorder.type_id === "") {
          toast.error("请选择 业务类型!")
          return false
        }
        if (this.salesorder.channel_name === undefined || this.salesorder.channel_name === "") {
          toast.error("请选择 渠道方!")
          return false
        }
        if (this.salesorder.trade_type === undefined || this.salesorder.trade_type === "") {
          toast.error("请选择  贸易方式!")
          return false
        }
        if (this.salesorder.company_name === undefined || this.salesorder.company_name === "") {
          toast.error("请选择  主体名称!")
          return false
        }
        if (isEmpty(this.salesorder.sales_name)) {
          toast.error("请选择  销售员名称!")
          return false
        }
        if (this.salesorder.contract_type === undefined || this.salesorder.contract_type === "") {
          toast.error("请选择  合同类型!")
          return false
        }
        if (this.salesorder.invoice_type === undefined || this.salesorder.invoice_type === "") {
          toast.error("请选择  发票类型!")
          return false
        }
        if (this.salesorder.store_id === undefined || this.salesorder.store_id === "") {
          toast.error("请选择  店铺!")
          return false
        }

        if (this.salesorder.sales_warehouse === undefined || this.salesorder.sales_warehouse === ""||this.salesorder.sales_warehouse == null) {
          toast.error("请选择  仓库!")
          return false
        }
        if (this.salesorder.sales_time === undefined || this.salesorder.sales_time === "") {
          toast.error("请填写  售卖时间!")
          return false
        }
        let itemData = this.$refs.itemList.tableArray
        for (let i = 0; i < itemData.length; i++) {
          if (itemData[i].product_cost_tax < 0) {
            toast.error("含税售价必须大于等于0!")
            return false
          }
          if (itemData[i].qty<=0){
            toast.error("售卖数量必须大于0!")
            return false
          }
        }
        this.salesorder.sales_time = second(this.salesorder.sales_time)
        for (let i = 0; i < this.salesOrderItem.length; i++) {
          saveSalesOrderItem(this.salesOrderItem[i])
        }
        store.dispatch('salesorder/save', this.salesorder).then(res => {
          if (res.data.code==0){
            toast.success('数据已保存!')
            store.dispatch('salesorder/changestatus', {id: this.salesorder.order_id, status: 1}).then(res => {
              if (res.data.code == 0) {
                if (res.data.data.ext!=undefined){
                  toast.error(res.data.data.ext.information)
                }else {
                  toast.success("提交成功")
                   this.$router.push({name: 'apps-salesorder-edit'})
                  //this.$router.push({name: 'apps-salesorder-edit'})
                  location.reload()
                }
              } else {
                toast.error(res.data.data)
                this.$router.push({name: 'apps-salesorder-list'})
              }
            })
          }else {
            toast.error(res.data.data)
          }
        })
        this.salesorder.sales_time = toTime(this.salesorder.sales_time)
      }
    }


    return {
      edit,
      view,
      cancel,
      save,
      toTime,
      toDate,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      changeSelect,
      second,

      getCompanyByCompanyTypeIsOne,
      getCompanyByCompanyTypeIsTwo,
      getUser,
      onSelectUser,
      onSelectContract,
      addExtraCostSubtotal,
      fromChildren,
      saveSalesOrderItem,
      setStore,
      createStore,
      fromChildrenDB,
      onUploaded,
      saveAndSubmit,
    }
  },
  created() {
    this.oneSwitch = getCodeLabel("setting","one_switch")
    this.id = this.$route.query.id || 0;
    this.readonly = this.$route.params.readonly || null;
    if (this.$route.params.readonly===0||this.id===0){
      this.readonly=0
    }
    const userData = getUserData()
    this.user = userData

    this.edit()
  },
  watch: {
    channelName:{
      handler(newValue,oldValue){
        this.storeArray = []
        store.dispatch('store/search',{company_id:isEmpty(this.salesorder.channel_id)?0:this.salesorder.channel_id})
            .then(response => {
              let data = response.data.data.list
              for (let i = 0; i < data.length; i++) {
                this.storeArray.push({"label":data[i].store_name,"store_id":data[i].store_id,"store_company_id":data[i].company_id})
              }
            })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
